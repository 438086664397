/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import BackImg from 'img/back-logo.png'
import ShineImg from 'img/shine.png'
import { ParseContent, Image } from 'components/shared'

// Blog
import Blog from 'components/related/Blog'
import Blogik, { BlogConsumer } from 'components/shared/Blogik'

const Content = styled(ParseContent)`
  h1 {
    br {
      display: none;
    }
  }

  h4,
  h5 {
    letter-spacing: 2px;
  }

  h4 {
    color: rgba(197, 197, 197, 0.4);
    font-size: ${(props) => props.theme.font.size.m};
  }

  & table {
    width: 100%;

    td {
      padding-right: 30px;
      vertical-align: top;
    }

    p {
      font-size: ${(props) => props.theme.font.size.sm};
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => (
  <Layout activeMenu="Blogs">
    <SEO yoast={yoast} path={path} />
    
    <div className="py-lg-5 py-4" />

    <div className="container">
      <Content content={acf.banner.description} />
    </div>

    <div className="pb-5" />
    <ShineImage>
      <Image src={ShineImg} style={{ width: '100%' }} />
    </ShineImage>

    <UBXImage>
      <Image src={BackImg} style={{ width: '100%' }} />
    </UBXImage>

    {/* BLOGS */}
    <Blogik
      settings={{
        limit: null,
      }}
    >
      <BlogConsumer>
        {({ hasPosts, posts }) => (
          <div className="py-lg-5 py-3 mt-lg-5 mt-3">
            {hasPosts && (
              <div className="container py-lg-5 py-3">
                <Blog posts={posts} />
              </div>
            )}
          </div>
        )}
      </BlogConsumer>
    </Blogik>

    <div className="py-5" />
  </Layout>
)

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          description
          animation
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

const UBXImage = styled.div`
  position: absolute;
  width: 80%;
  margin-top: 0;
  background-size: contain !important;
  background-position-x: 0 !important;
  @media screen and (max-width: 768px) {
    margin-top: 0;
    width: 100%;
  }

  & .gatsby-image-wrapper {
    max-height: 250px;
    overflow: initial !important;

    & img {
      max-height: 350px;
      object-fit: contain;
    }
  }
`

const ShineImage = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`

export default PageTemplate
